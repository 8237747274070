import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';

import {
  TechRadarApi,
  TechRadarLoaderResponse
} from '@backstage/plugin-tech-radar';

import { radartechdata } from './tech-radar/radar-tech-data';
import { techRadarApiRef } from '@backstage/plugin-tech-radar';
import { exploreToolsConfigRef } from '@backstage/plugin-explore-react';
import { fortumTools } from './tools';

class TechRadarClient implements TechRadarApi {
  async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
  if (id == 'foresight') {
    const radarforesightdata = require('./tech-radar/radar-foresight-data.json');
    convertDateString(radarforesightdata);
    return radarforesightdata;
  }
  else if (id == 'coreit'){
    const radarcoreitdata = require('./tech-radar/radar-coreit-data.json');
    convertDateString(radarcoreitdata);
    return radarcoreitdata;
  }
  else if (id == 'cos'){
    const radarcosdata = require('./tech-radar/radar-cos-dco-data.json');
    convertDateString(radarcosdata);
    return radarcosdata;
  }
  else {
    return radartechdata;
  }
  }
}

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory(techRadarApiRef, new TechRadarClient()),

  createApiFactory({
    api: exploreToolsConfigRef,
    deps: {},
    factory: () => ({
      async getTools() {
        return fortumTools;
      },
    }),
  }),
];

const convertDateString = (jsonObj: any) => {
  if (jsonObj.hasOwnProperty('entries')) {
    jsonObj.entries.forEach((entry: { timeline: any[]; }) => {
      entry.timeline.forEach((timeline: { date: string | Date }) => {
        if ( typeof timeline.date === 'string' ) {
          timeline.date = new Date(timeline.date);
        }
      });
    });
  }
}
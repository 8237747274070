export const radartechdata = {
  quadrants: [{ id: 'sysops', name: 'Cloud, DevSecOps, QA & SRE' }, { id: 'processes', name: 'Processes and Methods' }, { id: 'storage', name: 'Databases, Message Queues and Data Processing' }, { id: 'languages', name: 'Languages & Frameworks' }],
  rings: [{ id: 'use', name: 'USE', color: '#93c47d' }, { id: 'trial', name: 'TRIAL', color: '#0864ff' }, { id: 'assess', name: 'ASSESS', color: '#f9e311' }, { id: 'hold', name: 'HOLD', color: '#ea2929' }],
  entries: [
    /*
     Languages & Frameworks
     Use: Javascript, Typescript, Java 11, Python 3, C#, Kotlin, .Net, C++
     Trial:
     Assess: Java 8 (or older)
     Hold: Python 2
    */
    {
     url: '#',
     key: 'javascript',
     id: 'javascript',
     title: 'Javascript',
     quadrant: 'languages',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'Javascript',
       },
     ],
   },
   {
     url: '#',
     key: 'java11',
     id: 'java11',
     title: 'Java 11',
     quadrant: 'languages',
     timeline: [
      {
        moved: 0,
        ringId: 'use',
        date: new Date('2020-06-23'),
        description:
          'Java 11',
      },
      {
        moved: -1,
        ringId: 'hold',
        date: new Date('2023-04-18'),
        description:
          'Java 11',
      },
     ],
   },
   {
     url: '#',
     key: 'java17',
     id: 'java17',
     title: 'Java 17',
     quadrant: 'languages',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2023-04-18'),
         description:
           'Java 17',
       },
     ],
   },
   {
     url: '#',
     key: 'typescript',
     id: 'typescript',
     title: 'Typescript',
     quadrant: 'languages',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'Typescript',
       },
     ],
   },
   {
     url: '#',
     key: 'python3',
     id: 'python3',
     title: 'Python 3',
     quadrant: 'languages',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'python 3',
       },
     ],
   },
   {
     url: '#',
     key: 'c#',
     id: 'c#',
     title: 'C#',
     quadrant: 'languages',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'C#',
       },
     ],
   }, 
   {
     url: '#',
     key: 'scala',
     id: 'scala',
     title: 'Scala',
     quadrant: 'languages',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-11-09'),
         description:
           'scala',
       },
     ],
   },   
   {
     url: '#',
     key: 'clojure',
     id: 'clojure',
     title: 'Clojure',
     quadrant: 'languages',
     timeline: [
       {
         moved: 0,
         ringId: 'trial',
         date: new Date('2022-11-22'),
         description:
           'clojure',
       },
     ],
   },   
   {
     url: '#',
     key: 'kotlin',
     id: 'kotlin',
     title: 'Kotlin',
     quadrant: 'languages',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'kotlin',
       },
     ],
   },  
   {
     url: '#',
     key: 'dotnet',
     id: 'dotnet',
     title: '.Net',
     quadrant: 'languages',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           '.Net',
       },
     ],
   },  
   {
     url: '#',
     key: 'c++',
     id: 'c++',
     title: 'C++',
     quadrant: 'languages',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'C++',
       },
     ],
   },  
   {
     url: '#',
     key: 'python2',
     id: 'python2',
     title: 'Python 2',
     quadrant: 'languages',
     timeline: [
       {
         moved: 0,
         ringId: 'hold',
         date: new Date('2020-06-23'),
         description:
           'Python 2',
       },
     ],
   },  
   {
     url: '#',
     key: 'java8',
     id: 'java8',
     title: 'Java 10 or older',
     quadrant: 'languages',
     timeline: [
       {
         moved: 0,
         ringId: 'assess',
         date: new Date('2020-06-23'),
         description:
           'Java 10 or older',
       },
     ],
   },
    //
   /*
     Databases, Message Queues and Storage
     Use: MongoDB, Snowflake, ElasticSearch, Postgres, MySQL, DynamoDB, Timescale, Kafka
     Trial: MongoDB TS
     Assess: Cassandra, RabbitMQ
     Hold: 
   */
   {
     url: '#',
     key: 'mongodb',
     id: 'mongodb',
     title: 'MongoDB',
     quadrant: 'storage',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'MongoDB is used by many projects e.g. Hiven, Spring and CoS',
       },
     ],
   },
   {
     url: '#',
     key: 'snowflake',
     id: 'snowflake',
     title: 'Snowflake',
     quadrant: 'storage',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'snowflake',
       },
     ],
   },
   {
     url: '#',
     key: 'databricks',
     id: 'databricks',
     title: 'Databricks',
     quadrant: 'storage',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2022-11-09'),
         description:
           'databricks',
       },
     ],
   },
   {
     url: '#',
     key: 'elasticsearch',
     id: 'elasticsearch',
     title: 'Elasticsearch',
     quadrant: 'storage',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'elasticsearch',
       },
     ],
   },
   {
     url: '#',
     key: 'postgres',
     id: 'postgres',
     title: 'Postgres',
     quadrant: 'storage',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'postgres',
       },
     ],
   },
   {
     url: '#',
     key: 'mysql',
     id: 'mysql',
     title: 'MySQL',
     quadrant: 'storage',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'mysql',
       },
     ],
   },
   {
     url: '#',
     key: 'dynamodb',
     id: 'dynamodb',
     title: 'AWS DynamoDB',
     quadrant: 'storage',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'dynamodb',
       },
     ],
   },
   {
     url: '#',
     key: 'kafka',
     id: 'kafka',
     title: 'Kafka',
     quadrant: 'storage',
     timeline: [
       {
         moved: -1,
         ringId: 'assess',
         date: new Date('2022-10-21'),
         description:
           'kafka',
       },
     ],
   },
   {
     url: '#',
     key: 'timescale',
     id: 'timescale',
     title: 'Timescale',
     quadrant: 'storage',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'timescale',
       },
     ],
   },
   {
     url: '#',
     key: 'azuresql',
     id: 'azuresql',
     title: 'Azure SQL',
     quadrant: 'storage',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2023-06-06'),
         description:
           'azuresql',
       },
     ],
   },
   {
    url: '#',
    key: 'azuredatafactory',
    id: 'azuredatafactory',
    title: 'Azure Data Factory',
    quadrant: 'storage',
    timeline: [
      {
        moved: 0,
        ringId: 'use',
        date: new Date('2023-06-06'),
        description:
          'azure data factory',
      },
    ],
   },
   {
    url: '#',
    key: 'azuresynapse',
    id: 'azuresynapse',
    title: 'Azure Synapse',
    quadrant: 'storage',
    timeline: [
      {
        moved: 0,
        ringId: 'use',
        date: new Date('2023-06-06'),
        description:
          'azure synapse',
      },
    ],
   },
   {
     url: '#',
     key: 'cassandra',
     id: 'cassandra',
     title: 'Cassandra',
     quadrant: 'storage',
     timeline: [
       {
         moved: 0,
         ringId: 'assess',
         date: new Date('2020-06-23'),
         description:
           'cassandra',
       },
     ],
   },
   {
     url: '#',
     key: 'rabbitmq',
     id: 'rabbitmq',
     title: 'RabbitMQ',
     quadrant: 'storage',
     timeline: [
       {
         moved: 0,
         ringId: 'assess',
         date: new Date('2020-06-23'),
         description:
           'rabbitmq',
       },
     ],
   },
   /* Processes and Methodology
      Use: Well-Architecture Reviews, EA Review, Security Threat Modeling, Service Design
      Trial: Team KPI Scorecard
      Assess: SLOs
      Hold:   
   */
   {
     url: '#',
     key: 'war',
     id: 'war',
     title: 'Well-Architected Review',
     quadrant: 'processes',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'war',
       },
     ],
   },
   {
     url: 'https://backstage.fortum.tech/docs/default/Component/technology-handbook/DigitalDevelopmentGuidelines/',
     key: 'ddhandbook',
     id: 'ddhandbook',
     title: 'Digital Development Handbook',
     quadrant: 'processes',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2022-10-21'),
         description:
           'A high-level collection of the most important best practices and Fortum guideline references for Digital Development.',
       },
     ],
   },
   {
     url: '#',
     key: 'ear',
     id: 'ear',
     title: 'Enterprise Architecture Review',
     quadrant: 'processes',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'ear',
       },
     ],
   },
   {
     url: '#',
     key: 'stm',
     id: 'stm',
     title: 'Security Threat Modeling',
     quadrant: 'processes',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'stm',
       },
     ],
   },
   {
     url: '#',
     key: 'datacatalog',
     id: 'datacatalog',
     title: 'Data Catalog (Alation)',
     quadrant: 'processes',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2022-11-22'),
         description:
           'Alation is the technology behind Fortum Data Catalog',
       },
     ],
   },
   {
     url: '#',
     key: 'sdesign',
     id: 'sdesign',
     title: 'Service Design',
     quadrant: 'processes',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'sdesign',
       },
     ],
   },
   {
     url: '#',
     key: 'kpiscorecard',
     id: 'kpiscorecard',
     title: 'Team KPI Scorecard',
     quadrant: 'processes',
     timeline: [
       {
         moved: 0,
         ringId: 'assess',
         date: new Date('2022-10-21'),
         description:
           'kpiscorecard',
       },
     ],
   },
   {
     url: '#',
     key: 'slos',
     id: 'slos',
     title: 'Service Level Objectives (SLOs)',
     quadrant: 'processes',
     timeline: [
       {
         moved: 0,
         ringId: 'assess',
         date: new Date('2020-06-23'),
         description:
           'slos',
       },
     ],
   }, 
   /* Cloud, DevSecOps & SRE
      Use: AWS, Azure, Github Actions, Jenkins, Sonarqube, JFrog Artifactory, AquaSecurity, Terraform
      Trial: IriusRisk, JFrog X-Ray
      Assess: on-premises
      Hold: TravisCI, CloudFormation, Azure ARM
   */
    {
      url: 'https://fortum.sharepoint.com/sites/BTDevOpsTeam/SitePages/Continuous-Integration.aspx',
      key: 'github-actions',
      id: 'github-actions',
      title: 'GitHub Actions',
      quadrant: 'sysops',
      timeline: [
        {
          moved: 0,
          ringId: 'use',
          date: new Date('2020-06-23'),
          description:
            `GitHub Actions makes it easy to automate your software workflows with CI/CD. At Fortum
            CI can be built with public runners and CD with private runners.`,
        },
      ],
    },
    {
     url: '#',
     key: 'aws',
     id: 'aws',
     title: 'AWS',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'aws',
       },
     ],
   },
   {
     url: '#',
     key: 'azure',
     id: 'azure',
     title: 'Azure',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'azure',
       },
     ],
   },
   {
     url: 'https://confluence.fortum.com/display/LA004984/Spot',
     key: 'spotio',
     id: 'spotio',
     title: 'Spot.io',
     quadrant: 'sysops',
     timeline: [
       {
        moved: -1,
        ringId: 'hold',
        date: new Date('2024-08-16'),
        description:
          `Spot.io is used to manage instances in AWS and Azure to save money by optimizing the usage of virtual machines or instances. It charges based on the margin between the spot price and the on-demand price, aiming to use spot instances which are cheaper than on-demand instances.\n\rAfter a discussion in the Platform Engineering team the consensus leaned towards keeping Spot IO on hold without onboarding new teams due to its recent buggy performance and the administrative overhead involved, without getting enough cost savings to motivate increased use of Spot.io.`,
      },
      {
        moved: 0,
        ringId: 'use',
        date: new Date('2018-09-01'),
        description:
          `Spot.io is used to manage instances in AWS and Azure to save money by optimizing the usage of virtual machines or instances. It charges based on the margin between the spot price and the on-demand price, aiming to use spot instances which are cheaper than on-demand instances.`,
      },
     ],
   },
   {
     url: 'https://fortum.sharepoint.com/sites/BTDevOpsTeam/SitePages/Static-Application-Security-Testing.aspx',
     key: 'sonarqube',
     id: 'sonarqube',
     title: 'SonarQube',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           `SonarQube is a Static Application Security Testing (SAST) tool. 
           It can detect bugs, code smells and security vulnerabilities on 20+ programming languages.
           It can be used as a standalone, or as part of a GitHub Actions or Jenkins pipeline.`,
       },
     ],
   },   
   {
     url: 'https://fortum.sharepoint.com/sites/BTDevOpsTeam/SitePages/Artifactory-management.aspx',
     key: 'artifactory',
     id: 'artifactory',
     title: 'JFrog Artifactory',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           `JFrog Artifactory is a tool for artifactory management. 
           Typical artifacts are for instance compiled application binaries or libraries.`,
       },
     ],
   },   
   {
     url: 'https://fortum.sharepoint.com/sites/BTDevOpsTeam/SitePages/Container-Security-Testing.aspx',
     key: 'aquasec',
     id: 'aquasec',
     title: 'Aqua Security',
     quadrant: 'sysops',
     timeline: [
       {
         moved: -1,
         ringId: 'hold',
         date: new Date('2022-10-21'),
         description:
           `Aqua Security is a complete cloud native security platform with container, vm and serverless security. 
           It provides many services like instance Vulnerability Scanning and Dynamic Threat Analysis.`,
       },
     ],
   },   
   {
     url: 'https://fortum.sharepoint.com/sites/BTDevOpsTeam/SitePages/Infrastructure-as-Code.aspx',
     key: 'terraform',
     id: 'terraform',
     title: 'Terraform',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           `Terraform is the Fortum's recommended tool for Infrastructure as Code management.`,
       },
     ],
   },  
   {
     url: '#',
     key: 'JUnit5',
     id: 'JUnit5',
     title: 'JUnit 5',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'JUnit 5',
       },
     ],
   },  
   {
     url: '#',
     key: 'Selenium',
     id: 'Selenium',
     title: 'Selenium',
     quadrant: 'sysops',
     timeline: [
       {
         moved: -1,
         ringId: 'hold',
         date: new Date('2023-04-18'),
         description:
           'Selenium',
       },
     ],
   },   
   {
     url: '#',
     key: 'Cypress',
     id: 'Cypress',
     title: 'Cypress',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'Front end testing tool',
       },
     ],
   },  
   {
     url: '#',
     key: 'Playwright',
     id: 'Playwright',
     title: 'Playwright',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 0,
         ringId: 'trial',
         date: new Date('2022-11-22'),
         description:
           'Front end testing tool (similar to Cypress), used in Calvin and Hiven',
       },
       {
        moved: 1,
        ringId: 'use',
        date: new Date('2023-04-18'),
        description:
          'Front end testing tool (similar to Cypress, but multilanguage), used in R&W, CoS, and Hiven',
      },
     ],
   },     
   {
     url: '#',
     key: 'Detox',
     id: 'Detox',
     title: 'Detox',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'End-to-End Javascript mobile testing framework',
       },
     ],
   }, 
   {
     url: '#',
     key: 'K6',
     id: 'K6',
     title: 'K6',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'Load testing framework',
       },
     ],
   },    
   {
     url: '#',
     key: 'REST-assured',
     id: 'REST-assured',
     title: 'REST-assured',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'JAVA DSL for testing REST based services',
       },
     ],
   },
   {
     url: '#',
     key: 'Postman',
     id: 'Postman',
     title: 'Postman',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 0,
         ringId: 'use',
         date: new Date('2020-06-23'),
         description:
           'API testing tool',
       },
     ],
   },        
   {
     url: '#',
     key: 'jxray',
     id: 'jxray',
     title: 'JFrog X-Ray',
     quadrant: 'sysops',
     timeline: [
       {
         moved: -1,
         ringId: 'hold',
         date: new Date('2022-10-21'),
         description:
           `JFrog's X-Ray is an Artifactory plugin for vulnerability and dependency management. 
           This was tested as an one option for open-source license scanning.`,
       },
     ],
   },   
   {
     url: 'https://fortum.sharepoint.com/sites/BTDevOpsTeam/SitePages/Continuous-Monitoring.aspx',
     key: 'grafana',
     id: 'grafana',
     title: 'Grafana',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 1,
         ringId: 'use',
         date: new Date('2022-10-21'),
         description:
           `Grafana is a multi-platform open-source analytics and interactive visualization tool.`,
       },
     ],
   },   
   {
     url: '#',
     key: 'splunk',
     id: 'splunk',
     title: 'Splunk',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 0,
         ringId: 'trial',
         date: new Date('2022-10-21'),
         description:
           `Splunk for Observability is being tried by E2E Hydro teams. Also, regular Splunk is used by SOC`,
       },
     ],
   },   
   {
     url: '#',
     key: 'octopusdeploy',
     id: 'octopusdeploy',
     title: 'Octopus Deploy',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 0,
         ringId: 'trial',
         date: new Date('2022-10-24'),
         description:
           `Octopus Deploy is being used by Apollo MT team`,
       },
     ],
   },   
   {
     url: '#',
     key: 'argocd',
     id: 'argocd',
     title: 'Argo CD',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 0,
         ringId: 'trial',
         date: new Date('2022-10-24'),
         description:
           `ArgoCD is being used by Calvin team`,
       },
     ],
   },  
   {
     url: 'https://fortum.sharepoint.com/sites/BTDevOpsTeam/SitePages/Continuous-Monitoring.aspx',
     key: 'prometheus',
     id: 'prometheus',
     title: 'Prometheus',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 1,
         ringId: 'use',
         date: new Date('2022-10-21'),
         description:
           `Prometheus is an open-source systems monitoring and alerting toolkit.`,
       },
     ],
   },   
   {
     url: '#',
     key: 'on-premises',
     id: 'on-premises',
     title: 'On-premises',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 0,
         ringId: 'assess',
         date: new Date('2020-06-23'),
         description:
           'on-premises',
       },
     ],
   },   
   {
     url: '#',
     key: 'travisci',
     id: 'travisci',
     title: 'TravisCI',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 0,
         ringId: 'hold',
         date: new Date('2020-06-23'),
         description:
           'travisci',
       },
     ],
   },   
   {
     url: '#',
     key: 'cloudformation',
     id: 'cloudformation',
     title: 'Cloudformation',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 1,
         ringId: 'use',
         date: new Date('2022-10-21'),
         description:
           'cloudformation',
       },
     ],
   },  
   {
     url: '#',
     key: 'azurearm',
     id: 'azurearm',
     title: 'Azure ARM',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 1,
         ringId: 'assess',
         date: new Date('2022-10-21'),
         description:
           'azurearm',
       },
     ],
   }, 
   {
     url: '#',
     key: 'iriusrisk',
     id: 'iriusrisk',
     title: 'IriusRisk',
     quadrant: 'sysops',
     timeline: [
       {
         moved: 0,
         ringId: 'hold',
         date: new Date('2020-06-23'),
         description:
           `Tool for Threat Modeling. Has been tested and seen too complex for the purpose. 
           Will be discontinued starting Dec-2021.`,
       },
     ],
     }, 

  ],
}

import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  HomePageStarredEntities,
} from '@backstage/plugin-home';
import { Content, Page, InfoCard } from '@backstage/core-components';
import { HeaderSection } from './HeaderSection';
import { HomePageRSS } from '@roadiehq/backstage-plugin-home-rss';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

export const HomePage = () => {
  const config = useApi(configApiRef);
  return (
    <Page themeId="home">
      <Content>
        <HeaderSection />
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <HomePageStarredEntities />
          </Grid>
          <Grid item xs={12} md={6}>
            <InfoCard title="Digital Development Handbook">
              { <a href="https://backstage.fortum.tech/docs/default/component/technology-handbook/DigitalDevelopmentHandbook/" target="_self">
                Best practices and guideline references for Fortum Digital Development: applications and SW solutions that are designed, developed and operated by Fortum.<br /><br />
                <u>Read more</u>
              </a> }
              <div style={{ height: 20 }} />
            </InfoCard>
            <br />
            <InfoCard title="CoreIT Handbook">
              { <a href="https://backstage.fortum.tech/docs/default/component/technology-handbook/CoreItApplicationsHandbook/" target="_self">
                Best practices and guideline references for Fortum Core IT Applications: corporate IT applications that we purchase from vendors (either SaaS or self-hosted) and that support our business functions and productivity.<br /><br />
                <u>Read more</u>
              </a> }
              <div style={{ height: 20 }} />
            </InfoCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <HomePageRSS
              feedURL={`${config.getString(
                'backend.baseUrl',
              )}/api/proxy/fortum-tech-blogs/@fortum-tech`}
              title="Fortum Tech Blogs"
            />
          </Grid> 
        </Grid>
      </Content>
    </Page>
  );
};
